exports.components = {
  "component---src-components-blog-details-js": () => import("./../../../src/components/BlogDetails.js" /* webpackChunkName: "component---src-components-blog-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-profile-ahmad-jaan-js": () => import("./../../../src/pages/profile/ahmad-jaan.js" /* webpackChunkName: "component---src-pages-profile-ahmad-jaan-js" */),
  "component---src-pages-profile-arzoo-zaid-js": () => import("./../../../src/pages/profile/arzoo-zaid.js" /* webpackChunkName: "component---src-pages-profile-arzoo-zaid-js" */),
  "component---src-pages-profile-iffat-kalsoom-js": () => import("./../../../src/pages/profile/iffat-kalsoom.js" /* webpackChunkName: "component---src-pages-profile-iffat-kalsoom-js" */),
  "component---src-pages-profile-kamran-arshad-js": () => import("./../../../src/pages/profile/kamran-arshad.js" /* webpackChunkName: "component---src-pages-profile-kamran-arshad-js" */),
  "component---src-pages-profile-muhammad-bin-fayyaz-js": () => import("./../../../src/pages/profile/muhammad-bin-fayyaz.js" /* webpackChunkName: "component---src-pages-profile-muhammad-bin-fayyaz-js" */),
  "component---src-pages-profile-muhammad-omar-hashmi-js": () => import("./../../../src/pages/profile/muhammad-omar-hashmi.js" /* webpackChunkName: "component---src-pages-profile-muhammad-omar-hashmi-js" */),
  "component---src-pages-profile-zohaib-amir-js": () => import("./../../../src/pages/profile/zohaib-amir.js" /* webpackChunkName: "component---src-pages-profile-zohaib-amir-js" */),
  "component---src-pages-profile-zohaib-arshad-js": () => import("./../../../src/pages/profile/zohaib-arshad.js" /* webpackChunkName: "component---src-pages-profile-zohaib-arshad-js" */),
  "component---src-pages-profile-zoheb-nazir-js": () => import("./../../../src/pages/profile/zoheb-nazir.js" /* webpackChunkName: "component---src-pages-profile-zoheb-nazir-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

